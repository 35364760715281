<template>
  <div class="relative w-24 self-end">
    <div>
      <img v-if="skin" :src="skin.imageURL" class="w-24">
      <img v-else src="/public/counter-page/member-5.png" class="w-24">
    </div>
    <div v-for="(drink, index) in drinks" :key="index" 
      class="w-12 absolute z-20 right-[-10px]"
      :style="{ bottom: `${6 + getTotalHeight(drinks.slice(index + 1)) + (drink.name === 'Rainbow Rock' ? 4 : 0)}px` }"
      @mouseenter="hoveringGlassIndex = index" @mouseleave="hoveringGlassIndex = null" 
    >
      <div v-if="showDrinkDescription && hoveringGlassIndex === index" class="absolute w-64 -left-28 flex bottom-10 z-30">
        <div class="relative w-full flex justify-center">
          <img src="/public/counter-page/drink-bubble.png" class="w-full">
          <div class="absolute h-full p-5">
            <div v-if="userDetail.isBartender" class="flex flex-col items-center">
              <p>
                <span class="">{{ userDetail.nickName }}</span>
                เป็นบาร์เทนเดอร์ คอยชวนคุย รับฟัง ดูแลสารทุกข์สุขดิบคนในบาร์
              </p>
            </div>
            <div v-else-if="shouldShowSpecialDrink(userDetail)">
              <p>
                <span class="">{{ userDetail.nickName }}</span> กำลังดื่ม {{ drink.name }} ที่มีคนเลี้ยง
                โดยแก้วพิเศษจะอยู่ได้ 3 วัน
              </p>
            </div>
            <div v-else class="flex flex-col items-center">
              <p>
                <span class="">{{ userDetail.nickName }}</span> กำลังดื่ม {{ drinkById(userDetail.drinkId)?.name }} {{
                  drinkById(userDetail.drinkId)?.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        :src="drink.imageURL"
      />
    </div>
    <img v-if="hat" :src="hat.imageURL" class="hat">
    <img v-if="face" :src="face.imageURL" class="face">
    <img v-if="shirt && !skin" :src="shirt.imageURL" class="shirt">
    <img v-if="necklace" :src="necklace.imageURL" class="necklace">
    <img src="/counter-page/counter-seat.png" class="w-14 absolute left-3">
  </div>
</template>

<script>
import global from '~/mixins/global';
import sumBy from 'lodash/sumBy';

export default {
  mixins: [global],
  props: {
    showDrinkDescription: {
      type: Boolean,
      default: true,
    },
    userDetail: {
      type: Object,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      default: 1,
    },
    isBartender: {
      type: Boolean,
      default: false,
    },
    drinkId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    getTotalHeight (drinks) {
      const sum = sumBy(drinks, 'height');
      return sum;
    },
    drinkById (id) {
      return this.$store.drinks.find(drink => drink.id === id);
    },
  },
  computed: {
    loggedIn () {
      return this.userDetail && this.userDetail.authId;
    },
    hat () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'hat').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'hat').pop().accessory : null;
    },
    skin () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'skin').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'skin').pop().accessory : null;
    },
    necklace () {
      return this.loggedIn && this.isBartender ? { imageURL: 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/public-web-app/images/bartender/ribbon.png' } : this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'necklace').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'necklace').pop().accessory : null;
    },
    shirt () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'shirt').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'shirt').pop().accessory : null;
    },
    face () {
      return this.loggedIn && this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'face').length > 0 ? this.userDetail.accessoryInventory.filter(({ accessory }) => accessory.type === 'face').pop().accessory : null;
    },
    drinks () {
      if (this.loggedIn && this.shouldShowSpecialDrink(this.userDetail)) {
        return this.getSpecialDrinks(this.userDetail);
      }
      return this.isBartender ? [{ imageURL: 'https://ikuzbipnfkbymlctljcv.supabase.co/storage/v1/object/public/public-web-app/images/bartender/shaker.png' }] : [{ imageURL: this.drinkById(this.drinkId)?.imageURL }];
    },
  },
  data () {
    return {
      hoveringGlassIndex: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.hat {
  position: absolute;
  width: 3.5rem;
  top: -10px;
  left: 10px;
}

.face {
  @apply absolute w-16;
  top: 9px;
  left: 8px;
  z-index: 15;
}

.necklace {
  @apply absolute w-20;
  top: 30px;
}

.shirt {
  @apply absolute w-20;
  top: 30px;
}
</style>
